import { createStyles, Theme } from '@material-ui/core/styles';

import { colors } from '@shared/theme/colours';

import { SwitchSize, SwitchType } from './Switch';
export const styles = ({ spacing }: Theme) =>
  createStyles({
    root: {
      position: 'relative',
    },
    label: {
      margin: spacing(0, 3, 0, 0),
      userSelect: 'none',
    },
    switchRoot: {},
    switchPrimary: {},
    switchThumb: {},
    switchBase: {},
    switchTrack: {},
    tooltipContainer: {
      display: 'inline-flex',
    },
    [SwitchType.default]: {},
    [SwitchType.square]: {
      '& $switchRoot': {
        width: 40,
        height: 20,
        borderRadius: 4,
        padding: 0,

        '&:hover': {
          '& $switchTrack': {
            background: '#D1D6DF',
          },
        },
      },
      '& $switchPrimary': {
        '&.Mui-checked': {
          color: colors.white.lightest,
        },

        '&.Mui-checked + .MuiSwitch-track': {
          backgroundColor: `${colors.kato.navy[950]} !important`,
          opacity: 1,
          borderRadius: 4,
        },
      },
      '& $switchBase': {
        padding: '3px !important',
      },
      '& $switchThumb': {
        width: 14,
        height: 14,
        borderRadius: 4,
      },
      '& $switchTrack': {
        backgroundColor: '#EAECF0',
        opacity: 1,
        borderRadius: 4,
      },
    },
    [SwitchType.round]: {
      '& $switchRoot': {
        width: 40,
        height: 20,
        borderRadius: 12,
        padding: 0,

        '&:hover': {
          '& $switchTrack': {
            background: '#D1D6DF',
          },
        },
      },

      '& $switchPrimary': {
        '&.Mui-checked': {
          color: colors.white.lightest,
        },

        '&.Mui-checked + .MuiSwitch-track': {
          backgroundColor: `${colors.kato.navy[950]} !important`,
          opacity: 1,
          borderRadius: 12,
        },
      },
      '& $switchBase': {
        padding: '3px !important',
        background: 'none',
      },
      '& $switchThumb': {
        width: 14,
        height: 14,
        borderRadius: 12,
      },
      '& $switchTrack': {
        backgroundColor: '#EAECF0',
        opacity: 1,
        borderRadius: 12,
        transition: 'all 200ms ease-in-out',
      },
    },
    [SwitchSize.medium]: {},
    [SwitchSize.small]: {
      '& $switchRoot': {
        width: 28,
        height: 16,
      },
      '& $switchBase': {
        padding: '2px !important',
      },
      '& $switchThumb': {
        width: 12,
        height: 12,
      },
      '& .MuiSwitch-switchBase.Mui-checked': {
        transform: 'translateX(12px) !important',
      },
    },
  });
