import { createStyles, Theme } from '@material-ui/core/styles';

import { colors } from '@shared/theme/colours';
import { InputSize } from '@shared/types/common/input';

export const styles = ({ spacing }: Theme) =>
  createStyles({
    root: {},
    label: {
      color: '#1D2433', //TODO replace after new palette creating
      marginBottom: spacing(3),
    },
    picker: {
      '& .MuiOutlinedInput-adornedStart': {
        '&.MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline': {
          border: `1px solid ${colors.blue.dark} !important`,
        },

        '&.MuiOutlinedInput-root.Mui-error .MuiOutlinedInput-notchedOutline': {
          border: `1px solid #EA3418 !important`, //TODO replace after new palette creating
        },

        '&:hover': {
          '&.MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline': {
            borderColor: '#9EA6B3', // TODO: Change after palette updated
          },
        },

        paddingLeft: 0,
        backgroundColor: colors.white.lightest,
        width: 110,

        '& .MuiInputAdornment-positionStart': {
          marginRight: 0,

          '& .MuiIconButton-root': {
            padding: spacing(1.5),

            '& .MuiIconButton-label': {
              '& .MuiSvgIcon-root': {
                fontSize: 16,
                color: colors.grey.main,
              },
            },
          },
        },
      },

      '&.MuiOutlinedInput-inputAdornedStart': {
        fontSize: 13,
        paddingRight: spacing(1.5),
        paddingBottom: spacing(1.75),
      },

      '& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline': {
        border: `1px solid ${colors.blue.dark} !important`,
      },
    },
    pickedDisabled: {
      pointerEvents: 'none',
      cursor: 'not-allowed',
      userSelect: 'none',

      '& $clearIcon': {
        display: 'none',
      },

      '& .MuiOutlinedInput-adornedStart': {
        backgroundColor: colors.kato.grey[200],
        boxShadow: 'none',
        color: colors.kato.grey[400],

        '& .MuiInputAdornment-positionStart .MuiIconButton-root .MuiIconButton-label': {
          '& svg': {
            color: `${colors.kato.grey[400]} !important`,
          },
        },

        '&.Mui-disabled > input': {
          color: 'currentColor',
        },

        '& > .MuiOutlinedInput-notchedOutline': {
          borderColor: colors.kato.grey[200],
        },
      },
    },
    errorText: {
      color: colors.red.darkish,
      fontSize: 13,
    },
    clearIcon: {
      cursor: 'pointer',
      color: colors.grey.main,
      fontSize: 18,
    },

    // Sizes
    [InputSize.small]: {
      '& .MuiOutlinedInput-adornedStart': {
        height: 32,
      },
    },
    [InputSize.mediumLegacy]: {
      '& .MuiOutlinedInput-adornedStart': {
        height: 36,
      },
    },
    [InputSize.medium]: {
      '& .MuiOutlinedInput-adornedStart': {
        height: 40,
      },
    },
    [InputSize.large]: {
      '& .MuiOutlinedInput-adornedStart': {
        height: 48,
      },
    },
  });
