import { createStyles, Theme } from '@material-ui/core/styles';

import { colors } from '@shared/theme/colours';

export default ({ spacing }: Theme) =>
  createStyles({
    root: {
      overflow: 'hidden',
      textOverflow: 'ellipsis',
      whiteSpace: 'nowrap',
      fontSize: 14,
    },
    tooltip: {
      fontSize: 14,
      padding: spacing(4, 3),
      textAlign: 'center',
    },
    arrow: {
      fontSize: 13,
      '&::before': {
        backgroundColor: colors.black.darkest,
        border: `2px solid ${colors.black.darkest}`,
      },
    },
  });
