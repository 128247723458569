import { alpha, Theme } from '@material-ui/core/styles';

import { getStyles } from '@core/theme/utils/with-styles';
import { colors } from '@shared/theme/colours';

// NOTE: Refer to "commonStyles" name. Cannot be used via classes.* directly. Will be spread, e.g.:
// createStyles({ ...commonStyles(theme), someClass: { someKey: 'someValue' } }
export const commonStyles = ({ spacing }: Theme) => {
  const sharedPadding = spacing(2, 0, 1, 0);

  return getStyles({
    root: {},
    rootFullWidth: {
      width: '100%',
    },
    rootNoBorder: {
      backgroundColor: 'transparent',
      '& .MuiOutlinedInput-notchedOutline': {
        borderColor: 'transparent !important',
      },

      '& .MuiOutlinedInput-root:not(.Mui-disabled):hover .MuiOutlinedInput-notchedOutline': {
        borderColor: `${colors.black.darkest} !important`,
      },

      '& .MuiOutlinedInput-root:not(.Mui-disabled).Mui-focused .MuiOutlinedInput-notchedOutline': {
        border: `1px solid ${colors.black.darkest} !important`,
        boxShadow: 'none !important',
      },
    },
    marginDenseRoot: {},
    select: {
      '&:not(.Mui-disabled):hover': {
        '&.MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline': {
          borderColor: '#9EA6B3', // TODO: Change after palette updated
        },
      },

      '&.MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline': {
        border: `1px solid ${colors.blue.dark} !important`,
        boxShadow: '0px 0px 0px 4px rgba(50, 77, 252, 0.1)',
        transition: 'border-color 0.3s ease, box-shadow 0.3s ease',
      },

      '&.MuiOutlinedInput-root.Mui-error .MuiOutlinedInput-notchedOutline': {
        borderColor: `#EA3418 !important`, //TODO replace after new palette creating
      },
    },
    selectDisabled: {
      '&.MuiOutlinedInput-root.Mui-disabled .MuiOutlinedInput-notchedOutline': {
        borderColor: '#D1D6DF', // TODO: Change after palette updated
      },

      '&.MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline': {
        borderColor: `${alpha(colors.black.darkest, 0.23)} !important`,
      },

      '& $muiIcon': {
        display: 'none',
      },
    },
    value: {
      color: 'currentColor',
      fontSize: 14,
      overflow: 'hidden',
      padding: sharedPadding,
      textAlign: 'left',
      textOverflow: 'ellipsis',
      width: '100%',
    },
    valueMultiple: {},
    muiSelectRoot: {},
    muiSelect: {
      color: colors.black.darker,
      cursor: 'pointer',

      '&.Mui-disabled': {
        backgroundColor: '#F2F4F7', // TODO: Change after palette updated
        boxShadow: 'none',
        color: '#7E8899', // TODO: Change after palette updated
        cursor: 'not-allowed',
        pointerEvents: 'none',
      },
    },
    muiIcon: {
      opacity: 1,
      right: 4,
      top: 'calc(50% - 11px)',
    },
    iconPlaceholder: {
      color: colors.grey.main,
      opacity: 0.7,
    },
    muiOutlined: {
      alignItems: 'center',
      backgroundColor: colors.white.lightest,
      display: 'flex',
      height: '100%',
      paddingBottom: 0,
      paddingTop: 0,

      '&.Mui-disabled': {
        backgroundColor: '#F2F4F7', // TODO: Change after palette updated
        borderColor: '#D1D6DF', // TODO: Change after palette updated
        boxShadow: 'none',
        color: '#7E8899', // TODO: Change after palette updated
        cursor: 'not-allowed',
        pointerEvents: 'none',
      },
    },
    menuItem: {
      fontSize: 14,
    },
    menuItemDisabled: {
      color: alpha(colors.grey.main, 0.7),
      pointerEvents: 'none',
    },
    menuItemSelected: {
      color: colors.blue.main,
    },
    labelRoot: {
      color: '#101828', //TODO replace after new palette creating
      fontSize: 14,
      fontWeight: 500,
      marginBottom: spacing(2),

      '&.Mui-focused': {
        color: colors.black.darker,
      },
    },
    placeholder: {
      color: colors.grey.main,
      padding: sharedPadding,
    },
    placeholderDisabled: {
      color: '#7E8899', //TODO replace after new palette creating
    },
    helperText: {
      paddingTop: spacing(1),
    },
  });
};
