import { FC } from 'react';

import { t } from '@core/i18n';
import { WithStyles, withStyles } from '@core/theme/utils/with-styles';
import { Ellipsis } from '@shared/components/ellipsis';
import { Flex } from '@shared/components/flex';
import {
  FaceClosedIcon,
  FaceNegativeIcon,
  FaceNeutralIcon,
  FacePendingIcon,
  FacePositiveIcon,
} from '@shared/icons/face-icons';
import { ViewingOutcome } from '@shared/models/viewing/viewings';

import { styles } from './ViewingFeedback.styles';

export interface ViewingFeedbackProps extends WithStyles<typeof styles> {
  status?: ViewingOutcome;
  feedbackText: string;
}

const ViewingFeedbackComponent: FC<ViewingFeedbackProps> = ({
  classes,
  status = ViewingOutcome.Pending,
  feedbackText = '',
}) => {
  switch (status) {
    case ViewingOutcome.Positive:
      return (
        <Flex direction="row" autoWidth={false} classes={{ root: classes.feedbackContainer }} alignItems="center">
          <FacePositiveIcon classes={{ root: classes.commentIcon }} />
          <div className={classes.commentStatus}>{t('positive_feedback')}:</div>
          <div className={classes.commentText}>
            <Ellipsis tooltipPlacement="bottom-start" maxWidth="20%" text={`“${feedbackText}”`} />
          </div>
        </Flex>
      );

    case ViewingOutcome.Closed:
    case ViewingOutcome.None:
    case ViewingOutcome.Cancelled:
      return (
        <Flex autoWidth={false} classes={{ root: classes.feedbackContainer }} alignItems="center">
          <FaceClosedIcon classes={{ root: classes.commentIcon }} />
          <div className={classes.commentStatus}>{t('closed_hyphen_no_feedback_given')}</div>
        </Flex>
      );

    case ViewingOutcome.Neutral:
      return (
        <Flex autoWidth={false} classes={{ root: classes.feedbackContainer }} alignItems="center">
          <FaceNeutralIcon classes={{ root: classes.commentIcon }} />
          <div className={classes.commentStatus}>{t('neutral_feedback')}:</div>
          <div className={classes.commentText}>
            <Ellipsis tooltipPlacement="bottom-start" maxWidth="20%" text={`“${feedbackText}”`} />
          </div>
        </Flex>
      );

    case ViewingOutcome.Negative:
      return (
        <Flex autoWidth={false} classes={{ root: classes.feedbackContainer }} alignItems="center">
          <FaceNegativeIcon classes={{ root: classes.commentIcon }} />
          <div className={classes.commentStatus}>{t('negative_feedback')}:</div>
          <div className={classes.commentText}>
            <Ellipsis tooltipPlacement="bottom-start" maxWidth="20%" text={`“${feedbackText}”`} />
          </div>
        </Flex>
      );

    case ViewingOutcome.Pending:
      return (
        <Flex autoWidth={false} classes={{ root: classes.feedbackPendingContainer }} alignItems="center" wrap="nowrap">
          <FacePendingIcon classes={{ root: classes.commentIcon }} />
          <div className={classes.commentPendingStatus}>{t('pending_feedback')}:</div>
          <Ellipsis
            maxWidth="70%"
            classes={{ root: classes.commentPendingText }}
            text={t('please_provide_feedback_on_the_viewing')}
          />
        </Flex>
      );
  }
};

export const ViewingFeedback = withStyles(styles)(ViewingFeedbackComponent);
