import { createStyles } from '@material-ui/core/styles';

export const styles = () =>
  createStyles({
    root: {
      width: 102,
    },
    select: {},
    container: {},
  });
