import cx from 'classnames';
import { FC, ReactNode } from 'react';

import { WithStyles, withStyles } from '@core/theme/utils/with-styles';
import { Button, ButtonProps } from '@shared/components/button';
import { Flex } from '@shared/components/flex';
import { AlertIcon } from '@shared/icons/alert';
import { AlertRoundIcon } from '@shared/icons/alert-round';
import { CheckCircleIcon } from '@shared/icons/check-circle';
import { normalizedHTMLText } from '@shared/utils/text';

import { styles } from './DrawerOverlay.styles';

export enum DrawerOverlayTheme {
  success = 'success',
  warning = 'warning',
  error = 'error',
}

export interface DrawerOverlayProps extends WithStyles<typeof styles> {
  additionalSection?: ReactNode;
  buttons: ButtonProps[];
  heading: string;
  message: string;
  theme: DrawerOverlayTheme;
}

const DrawerOverlayComponent: FC<DrawerOverlayProps> = ({
  additionalSection,
  classes,
  heading,
  message,
  buttons,
  theme,
}) => {
  return (
    <Flex classes={{ root: classes.root }} direction="column" alignItems="center">
      <Flex
        autoWidth={false}
        alignItems="center"
        justifyContent="center"
        classes={{ root: cx(classes.iconContainer, classes[theme]) }}
      >
        {theme === DrawerOverlayTheme.warning && <AlertIcon color="#DC6803" classes={{ root: classes.icon }} />}
        {theme === DrawerOverlayTheme.error && <AlertRoundIcon color="#D92D20" classes={{ root: classes.icon }} />}
        {theme === DrawerOverlayTheme.success && <CheckCircleIcon color="#039855" classes={{ root: classes.icon }} />}
      </Flex>
      <h3 className={classes.heading}>{heading}</h3>
      <p className={classes.message} dangerouslySetInnerHTML={{ __html: normalizedHTMLText(message) }} />
      {additionalSection && <div className={classes.additionalContent}>{additionalSection}</div>}
      <Flex classes={{ root: classes.actionContainer }}>
        {buttons.map((button, index) => (
          <Button key={index} {...button} classes={{ root: classes.button }} />
        ))}
      </Flex>
    </Flex>
  );
};

export const DrawerOverlay = withStyles(styles)(DrawerOverlayComponent);
