import { alpha, createStyles, Theme } from '@material-ui/core/styles';

import { colors } from '@shared/theme/colours';

export function styles({ spacing }: Theme) {
  return createStyles({
    root: {
      width: '100%',
    },
    label: {
      color: colors.grey.darker,
      fontSize: 14,
      marginBottom: 10,
      fontWeight: 500,
    },
    errorText: {},
    select: {
      borderRadius: 4,
      fontSize: 14,
      width: '100%',

      '&:hover': {
        '&.MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline': {
          borderColor: '#9EA6B3', // TODO: Change after palette updated
        },
      },

      '&.MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline': {
        border: `1px solid ${colors.blue.dark} !important`,
        boxShadow: '0px 0px 0px 4px rgba(50, 77, 252, 0.1)',
      },

      '&.MuiOutlinedInput-root.Mui-error .MuiOutlinedInput-notchedOutline': {
        borderColor: `#EA3418 !important`, //TODO replace after new palette creating
      },
    },
    selectNotFocused: {
      '&.MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline': {
        borderColor: `${alpha(colors.black.darkest, 0.23)} !important`,
        boxShadow: 'none',
      },
    },
    selectDisabled: {
      pointerEvents: 'none',

      '&.MuiOutlinedInput-root.Mui-disabled .MuiOutlinedInput-notchedOutline': {
        opacity: 0.5,
      },

      '&.MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline': {
        borderColor: `${alpha(colors.black.darkest, 0.23)} !important`,
      },

      '&:hover': {
        '&.MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline': {
          borderColor: alpha(colors.black.darkest, 0.23),
        },
      },

      '& $muiIcon': {
        display: 'none',
      },
    },
    search: {
      padding: '2px 20px 9px',
      borderBottom: `1px solid ${colors.grey.light}`,
    },
    searchField: {
      width: '100%',
    },
    group: {},
    groupItem: {
      padding: spacing(2, 1),
    },
    groupLabel: {
      textTransform: 'uppercase',
      color: colors.grey.main,
      display: 'block',
      padding: spacing(1, 2),
      fontSize: 12,
      fontWeight: 300,
    },
    value: {
      fontSize: 14,
      overflow: 'hidden',
      marginTop: 2,
      textOverflow: 'ellipsis',
      width: '100%',
      textAlign: 'left',
    },
    muiSelect: {},
    muiSelectNotFocused: {
      '&:focus': {
        backgroundColor: colors.white.lightest,
      },
    },
    muiIcon: {},
    muiOutlined: {
      borderRadius: 6,
      height: 36,
      paddingTop: 0,
      paddingBottom: 0,
      display: 'flex',
      alignItems: 'center',
      backgroundColor: colors.white.lightest,

      '&.Mui-disabled': {
        backgroundColor: '#F2F4F7', // TODO: Change after palette updated
        borderColor: colors.grey.lightish,
        boxShadow: 'none',
        cursor: 'not-allowed',
        opacity: 0.5,
        pointerEvents: 'none',
      },
    },
    menuItem: {
      fontSize: 14,
    },
    menuItemError: {
      color: colors.red.main,
    },
    menuItemDisabled: {
      color: colors.red.darkish,
      pointerEvents: 'none',
    },
    menuItemSelected: {
      color: colors.blue.main,
    },
    placeholder: {
      color: colors.grey.main,
      marginTop: 2,
    },
    placeholderDisabled: {
      color: alpha(colors.black.darkest, 0.4),
    },
    helperText: {
      paddingTop: spacing(1),
    },
  });
}
