import cx from 'classnames';
import { useState } from 'react';

import { WithStyles, withStyles } from '@core/theme/utils/with-styles';
import { FieldWithCard, FieldWithCardProps } from '@shared/components/fields/field-with-card';
import { NumberField } from '@shared/components/number-field';

import { styles } from './NumberFieldWithCard.styles';

type genericProps = Omit<FieldWithCardProps, 'field' | 'setFieldInputClass'>;
export interface NumberFieldWithCardProps extends WithStyles<typeof styles>, genericProps {
  disabled?: boolean;
  error?: boolean;
  errorText?: string;
  inputPlaceholder: string;
  inputValue?: string;
  onInputChange: (value: string) => void;
}

const NumberFieldWithCardComponent: React.FC<NumberFieldWithCardProps> = ({
  classes,
  disabled = false,
  error,
  errorText,
  inputPlaceholder,
  inputValue,
  onInputChange,
  cardLabel,
  cardSubLabel,
  ...otherProps
}) => {
  const [fieldInputClass, setFieldInputClass] = useState<string>('');
  const fieldNoBorderRadius = cardSubLabel && cardLabel;

  return (
    <FieldWithCard
      cardSubLabel={cardSubLabel}
      cardLabel={cardLabel}
      classes={{
        root: classes.root,
        cardLabel: classes.cardLabel,
        cardSubLabel: classes.cardSubLabel,
      }}
      {...otherProps}
      field={
        <NumberField
          classes={{
            root: classes.field,
            input: cx(
              classes.fieldInput,
              { [classes.noBorderRadius]: fieldNoBorderRadius },
              { [fieldInputClass]: !fieldNoBorderRadius }
            ),
          }}
          disabled={disabled}
          error={error}
          errorText={errorText}
          fullWidth
          placeholder={inputPlaceholder}
          value={inputValue}
          variant="outlined"
          onChange={(value) => onInputChange(value)}
        />
      }
      setFieldInputClass={setFieldInputClass}
    />
  );
};

export const NumberFieldWithCard = withStyles(styles)(NumberFieldWithCardComponent);
