import { FC, useMemo } from 'react';

import { withStyles, WithStyles } from '@core/theme/utils/with-styles';

import { styles } from './TimeSelector.styles';
import { numberPadding, transformToTime } from './TimeSelector.utils';
import { Option, SingleSelectOnChange } from '../select/Select.types';
import { SingleSelect, SingleSelectProps } from '../select/single-select';

export interface TimeSelectorProps extends WithStyles<typeof styles>, Omit<SingleSelectProps, 'classes' | 'options'> {
  time: string;
  onTimeChange: (time: string) => void;
}

const TimeSelectorComponent: FC<TimeSelectorProps> = ({ classes, time, onTimeChange, ...otherProps }) => {
  const options = useMemo(() => {
    const hours = Array(24)
      .fill('')
      .map(function (item, index) {
        return index;
      });
    const minutes = Array(4)
      .fill('')
      .map(function (item, index) {
        return index * 15;
      });

    return hours.reduce(function (options: Array<Option>, hour) {
      return options.concat(
        minutes.map(function (minute: number) {
          const minutes = numberPadding(minute);
          return {
            label: transformToTime(hour.toString(), minutes, true),
            id: transformToTime(numberPadding(hour), minutes),
          };
        })
      );
    }, []);
  }, []);

  const handleChange: SingleSelectOnChange = (e, value) => {
    onTimeChange(value.toString());
  };

  return (
    <div className={classes.container}>
      <SingleSelect
        placeholder="HH:MM"
        options={options}
        value={time}
        onChange={handleChange}
        classes={{
          root: classes.root,
          select: classes.select,
        }}
        {...otherProps}
      />
    </div>
  );
};

export const TimeSelector = withStyles(styles)(TimeSelectorComponent);
