import {
  FormLabel,
  InputAdornment,
  TextField as MuiTextField,
  TextFieldProps as MuiTextFieldProps,
} from '@material-ui/core';
import { withStyles, WithStyles } from '@material-ui/core/styles';
import cx from 'classnames';
import { ReactNode, useEffect, useState } from 'react';
import { FieldRenderProps } from 'react-final-form';

import { FieldHelperText } from '@shared/components/field-helper-text';
import { InputSize } from '@shared/types/common/input';
import { processDecimal } from '@shared/utils/number';

import { styles } from './NumberField.styles';

interface NumberFieldConfig {
  decimalPlaces?: number;
  max?: number;
  min?: number;
  roundIfWhole?: boolean;
  setMinIfEmpty?: boolean;
}

const processValue = (value: string, config: NumberFieldConfig): string => {
  // Convert to fixed decimal place, if supplied value is not integer
  const parsedValue = processDecimal(value, {
    decimalPlaces: config.decimalPlaces,
    roundIfWhole: config.roundIfWhole,
  });
  // console.log('processValue. value: ', value, 'parsedValue: ', parsedValue);

  // Return the min value if the value is smaller
  if (
    typeof config.min === 'number' &&
    // Set min if empty
    ((config.setMinIfEmpty && parsedValue === '') ||
      // Set min if value lower than min
      Number(parsedValue) < config.min)
  ) {
    return config.min.toString();
  }

  // Return the min value if the value is larger
  if (typeof config.max === 'number' && Number(parsedValue) > config.max) {
    return config.max.toString();
  }

  return parsedValue;
};

const validateValue = (value: string, config: NumberFieldConfig): boolean => {
  if (typeof config.min === 'number' && Number(value) < config.min) {
    return false;
  }
  if (typeof config.max === 'number' && Number(value) > config.max) {
    return false;
  }

  return true;
};

type Classes = WithStyles<typeof styles>;

export interface NumberFieldProps
  extends Omit<MuiTextFieldProps, 'classes' | 'margin' | 'onBlur' | 'onChange' | 'onWheel' | 'placeholder' | 'size'>,
    Classes {
  endIcon?: ReactNode;
  errorText?: string;
  decimalPlaces?: number;
  infoText?: string;
  label?: React.ReactNode;
  min?: number;
  max?: number;
  placeholder?: string;
  required?: boolean;
  roundIfWhole?: boolean;
  setMinIfEmpty?: boolean;
  size?: InputSize;
  startIcon?: React.ReactNode;
  step?: number;
  value?: number | string;
  warning?: boolean;
  warningText?: string;
  onChange: (value: string) => void;
}

export const NumberFieldAdapter = ({ input, meta, ...rest }: FieldRenderProps<string, any>) => {
  const errorMessage: string = (meta.submitFailed && meta.error) || '';

  return <NumberField {...input} {...rest} error={!!errorMessage} errorText={errorMessage} onChange={input.onChange} />;
};

const NumberFieldComponent: React.FC<NumberFieldProps> = ({
  className,
  classes,
  decimalPlaces,
  endIcon,
  error,
  errorText,
  fullWidth,
  infoText,
  inputProps,
  InputProps,
  label,
  min,
  max,
  placeholder,
  required,
  roundIfWhole = false,
  setMinIfEmpty = false,
  size = InputSize.mediumLegacy,
  startIcon,
  step,
  type,
  value,
  warning,
  warningText,
  onChange,
  ...otherProps
}) => {
  // Destructure classes only relevant to our wrapper component, pass otherClasses to mui
  const {
    root: rootClass,
    rootFullWidth: rootFullWidthClass,
    labelRoot: labelRootClass,
    input: inputClass,
    label: labelClass,
    labelError: labelErrorClass,
    helperText: helperTextClass,
    large,
    medium,
    mediumLegacy,
    small,
    ...otherClasses
  } = classes;

  // State
  const [localValue, setLocalValue] = useState<string>('');
  const [localError, setLocalError] = useState<boolean>(false);

  const processConfig: NumberFieldConfig = { decimalPlaces, max, min, roundIfWhole, setMinIfEmpty };

  // Update local value when parent value changes
  useEffect(() => {
    if (typeof value === 'number') {
      // console.log('setting number value to: ', value, value.toString());
      return setLocalValue(value.toString());
    } else if (typeof value === 'string') {
      // console.log('setting string value to: ', value, value);
      return setLocalValue(value);
    } else {
      // console.log('setting undefined value to: ', value, '');
      return setLocalValue('');
    }
  }, [value]);

  return (
    <div className={cx(rootClass, { [rootFullWidthClass]: fullWidth }, className)}>
      {label && (
        <FormLabel
          classes={{ root: cx(labelRootClass, labelClass), error: labelErrorClass }}
          component="legend"
          error={error}
        >
          {label}
        </FormLabel>
      )}
      <MuiTextField
        classes={otherClasses}
        error={localError || error}
        fullWidth={fullWidth}
        placeholder={placeholder || undefined}
        type="number"
        value={localValue}
        variant="outlined"
        InputProps={{
          classes: { root: cx(inputClass, classes[size]) },
          endAdornment: endIcon ? <InputAdornment position="end">{endIcon}</InputAdornment> : null,
          startAdornment: startIcon ? <InputAdornment position="start">{startIcon}</InputAdornment> : null,
          ...InputProps,
        }}
        inputProps={{ ...inputProps, min, max, step }}
        onBlur={(e) => {
          e.persist();

          const processedValue = processValue(e.target.value, processConfig);
          // console.log('processedValue: ', processedValue);

          setLocalError(false);
          setLocalValue(processedValue);
          onChange(processedValue);
        }}
        onChange={(e) => {
          e.persist();

          setLocalError(!validateValue(e.target.value, processConfig));
          setLocalValue(e.target.value);
        }}
        onWheel={(e) => {
          // TODO: Figure out why 'e' of type WheelEvent does not have blur() on it
          (e.target as HTMLInputElement).blur();
        }}
        {...otherProps}
      />
      <FieldHelperText
        classes={{ root: helperTextClass }}
        error={error}
        errorText={errorText}
        infoText={infoText}
        warning={warning}
        warningText={warningText}
      />
    </div>
  );
};

export const NumberField = withStyles(styles)(NumberFieldComponent);
