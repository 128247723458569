import { createQueryKeys, inferQueryKeys } from '@lukemorales/query-key-factory';
import { AxiosResponse } from 'axios';

import {
  getHTTPClient,
  queryClient,
  useMutation,
  UseMutationOptions,
  useQuery,
  UseQueryOptions,
} from '@core/http-client';
import { getQueriesAsSearch } from '@shared/utils/common';

import {
  TasksCreatePayload,
  TaskShowItem,
  TaskShowResponse,
  TasksLeaveFeedbackPayload,
  TasksListPayload,
  TasksListResponse,
  UpdateTaskPayload,
} from './tasksApi.types';

const $http = getHTTPClient();

export const tasksKeys = createQueryKeys('tasks', {
  all: null,
  list: (payload: TasksListPayload) => ({
    queryKey: [payload.query],
    queryFn: async ({ signal }) =>
      (await $http.get<TasksListResponse>(`/tasks${getQueriesAsSearch(payload.query)}`, { signal })).data,
  }),
  show: (id: string) => ({
    queryKey: [id],
    queryFn: async () => (await $http.get<TaskShowResponse>(`/tasks/${id}`)).data,
  }),
});

export type TasksQueryKeys = inferQueryKeys<typeof tasksKeys>;

export const useTasksListQuery = (payload: TasksListPayload, options?: UseQueryOptions<TasksListResponse>) =>
  useQuery<TasksQueryKeys['list']['queryKey'], TasksListResponse>({
    ...options,
    keepPreviousData: true,
    ...tasksKeys.list(payload),
  });

export const useTasksShowQuery = (id: string, options?: UseQueryOptions<TaskShowResponse>) =>
  useQuery<TasksQueryKeys['show']['queryKey'], TaskShowResponse>({
    ...options,
    enabled: !!id,
    keepPreviousData: true,
    ...tasksKeys.show(id),
  });

export const useTasksCreateMutation = (opts?: UseMutationOptions<TasksCreatePayload, TaskShowItem>) =>
  useMutation({
    ...opts,
    mutationFn: async (payload: TasksCreatePayload) =>
      (await $http.post<TasksCreatePayload['body'], AxiosResponse<TaskShowItem>>(`/tasks`, payload.body)).data,
  });

export const useTasksUpdateMutation = (opts?: UseMutationOptions<UpdateTaskPayload, TaskShowItem>) =>
  useMutation({
    ...opts,
    mutationFn: async (payload: UpdateTaskPayload) => {
      const response = await $http.patch<UpdateTaskPayload['body'], AxiosResponse<TaskShowItem>>(
        `/tasks/${payload.id}`,
        payload.body
      );

      queryClient.setQueryData<TaskShowItem>(tasksKeys.show(payload.id.toString()).queryKey, (task) => {
        if (!task) {
          return;
        }

        return response.data;
      });

      return response.data;
    },
  });

export const useTasksDeleteMutation = (opts?: UseMutationOptions<Id, TaskShowItem>) =>
  useMutation({
    ...opts,
    mutationFn: async (payload: Id) => (await $http.delete(`/tasks/${payload}`)).data,
  });

export const useTasksLeaveFeedbackMutation = (opts?: UseMutationOptions<TasksLeaveFeedbackPayload, TaskShowItem>) =>
  useMutation({
    ...opts,
    mutationFn: async (payload: TasksLeaveFeedbackPayload) =>
      (
        await $http.put<TasksLeaveFeedbackPayload['body'], AxiosResponse<TaskShowItem>>(
          `/tasks/${payload.id}/feedback`,
          payload.body
        )
      ).data,
  });
