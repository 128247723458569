import { createStyles } from '@material-ui/core/styles';

import { colors } from '@shared/theme/colours';

export default () =>
  createStyles({
    root: {},
    label: {
      userSelect: 'none',
    },
    radio: {
      padding: 0,
      marginLeft: 0,
      marginRight: 10,
    },
    icon: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      width: 20,
      height: 20,
      backgroundColor: colors.white.lightest,
      color: colors.white.lightest,
      borderRadius: 10,
      lineHeight: 10,
      border: `1px solid ${colors.grey.light}`,
    },
    iconChecked: {
      color: colors.kato.navy[950],
    },
    checked: {
      width: 8,
      height: 8,
      borderRadius: 10,
      backgroundColor: colors.kato.navy[950],
    },
    radioChecked: {},
  });
