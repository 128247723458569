export const numberPadding = (value: string | number) => {
  if (typeof value === 'string' && value) {
    value = parseInt(value);
  }

  return value > 9 ? value.toString() : '0' + value.toString();
};

export const transformToTime = (hour: string, minute: number | string, meridiem?: boolean) => {
  let hourFormatted: string | number = numberPadding(hour);
  let postfix = '';

  if (meridiem) {
    const hourInt = parseInt(hour);
    const isPm = hourInt > 11;

    if (isPm) {
      hourFormatted = (hourInt > 12 ? hourInt - 12 : hourInt).toString();
    } else {
      hourFormatted = hourInt === 0 ? '12' : hour;
    }

    postfix = isPm ? 'pm' : 'am';
  }

  return hourFormatted + ':' + numberPadding(minute) + postfix;
};
