import cx from 'classnames';
import { FC, ReactNode, useMemo } from 'react';

import { t } from '@core/i18n';
import { WithStyles, withStyles } from '@core/theme/utils/with-styles';
import { DatePicker, MaterialDate } from '@shared/components/date-picker';
import { NumberFieldWithCard } from '@shared/components/fields/field-with-card/number-field-with-card';
import { Flex } from '@shared/components/flex';
import { RadioGroup, RadioGroupSerializerType } from '@shared/components/radio-group';
import { formatDateFrom, PICKER_DATE_FORMAT } from '@shared/utils/date';

import { styles } from './FilterPeriod.styles';

export enum FilterPeriodType {
  range = 'range',
  daysAgo = 'daysAgo',
  daysAhead = 'daysAhead',
}

export interface FilterPeriodState {
  ago?: string;
  ahead?: string;
  from: MaterialDate;
  to: MaterialDate;
  type: FilterPeriodType;
}

export interface FilterPeriodProps extends WithStyles<typeof styles> {
  action?: React.ReactNode;
  dateAutoWidth?: boolean;
  label: ReactNode | string;
  state: FilterPeriodState;
  supportedTypes?: FilterPeriodType[];
  onDaysChange: (day: string, value: string) => void;
  onPickerChange: (key: string, date: MaterialDate) => void;
  onRadioChange: (radio: FilterPeriodType) => void;
}

const FilterPeriodComponent: FC<FilterPeriodProps> = ({
  action,
  classes,
  dateAutoWidth,
  label,
  state,
  supportedTypes = [FilterPeriodType.range, FilterPeriodType.daysAgo, FilterPeriodType.daysAhead],
  onDaysChange,
  onPickerChange,
  onRadioChange,
}) => {
  const handleRadioChange = (id: Id) => onRadioChange(id as FilterPeriodType);

  const handlePickerChange = (type: string) => (date: MaterialDate) => {
    onPickerChange(type, date);
  };

  const handleDaysChange = (type: string) => (value: string) => {
    onDaysChange(type, value);
  };

  type LabelHash = { [key in FilterPeriodType]: string };

  const labelHash: LabelHash = {
    [FilterPeriodType.range]: t('range'),
    [FilterPeriodType.daysAgo]: t('days_ago'),
    [FilterPeriodType.daysAhead]: t('days_ahead'),
  };

  const filterPeriodRadios = useMemo(() => {
    return supportedTypes.map((type) => {
      return {
        id: type,
        label: labelHash[type],
        key: `filterRadio-${type}`,
      };
    });
  }, []);

  const datePickerConfig = useMemo(() => {
    return [
      {
        value: typeof state.from === 'string' ? new Date(formatDateFrom(state.from)) : state.from,
        onChange: handlePickerChange('from'),
        label: t('from'),
      },
      {
        value: typeof state.to === 'string' ? new Date(formatDateFrom(state.to)) : state.to,
        onChange: handlePickerChange('to'),
        label: t('to'),
        className: classes.periodTo,
      },
    ];
  }, [state.from, state.to, handlePickerChange]);

  const filterPeriodFieldContent = useMemo(() => {
    const config = {
      [FilterPeriodType.range]: (
        <Flex direction="row" wrap="nowrap">
          {datePickerConfig.map(({ label, className, ...pickerProps }) => (
            <Flex
              key={label}
              direction="row"
              wrap="nowrap"
              alignItems="center"
              classes={{ root: cx(classes.periodPicker, className) }}
            >
              <span className={classes.periodLabel}>{label}</span>
              <DatePicker
                autoWidth={dateAutoWidth}
                dateFormat={PICKER_DATE_FORMAT}
                placeholder="DD/MM/YYYY"
                classes={{ picker: classes.picker }}
                {...pickerProps}
              />
            </Flex>
          ))}
        </Flex>
      ),
      [FilterPeriodType.daysAgo]: (
        <Flex alignItems="center" wrap="nowrap" justifyContent="flex-start">
          <span className={classes.periodLabel}>{t('within_the_last')}</span>
          <NumberFieldWithCard
            cardLabelPosition="right"
            cardLabel={t('days')}
            classes={{
              root: classes.daysTextFieldRoot,
              field: classes.daysTextField,
              fieldInput: classes.daysTextFieldInput,
              cardLabel: classes.cardLabel,
            }}
            inputPlaceholder="..."
            inputValue={state.ago}
            onInputChange={handleDaysChange('ago')}
          />
        </Flex>
      ),
      [FilterPeriodType.daysAhead]: (
        <Flex alignItems="center" wrap="nowrap" justifyContent="flex-start">
          <span className={classes.periodLabel}>{t('within_the_next')}</span>
          <NumberFieldWithCard
            cardLabelPosition="right"
            cardLabel={t('days')}
            classes={{
              root: classes.daysTextFieldRoot,
              field: classes.daysTextField,
              fieldInput: classes.daysTextFieldInput,
              cardLabel: classes.cardLabel,
            }}
            inputPlaceholder="..."
            inputValue={state.ahead}
            onInputChange={handleDaysChange('ahead')}
          />
        </Flex>
      ),
    };

    return config[state.type as FilterPeriodType];
  }, [state.from, state.to, state, datePickerConfig]);

  return (
    <Flex direction="column" classes={{ root: classes.root }}>
      <Flex justifyContent="space-between">
        <span className={classes.label}>{label}</span>
        {action}
      </Flex>
      <RadioGroup
        value={state.type}
        options={filterPeriodRadios}
        serializerType={RadioGroupSerializerType.string}
        onChange={handleRadioChange}
        classes={{
          radios: classes.radios,
          formControl: classes.radiosControl,
        }}
      />
      {filterPeriodFieldContent}
    </Flex>
  );
};

export const FilterPeriod = withStyles(styles)(FilterPeriodComponent);
