import { FormLabel, RadioGroup as MuiRadioGroup } from '@material-ui/core';
import { withStyles, WithStyles } from '@material-ui/core/styles';
import cx from 'classnames';

import { FieldHelperText } from '@shared/components/field-helper-text';
import { Flex } from '@shared/components/flex';
import { Radio } from '@shared/components/radio';
import { Tooltip } from '@shared/components/tooltip';

import styles from './RadioGroup.styles';

export enum RadioGroupSerializerType {
  string,
  number,
}

export interface RadioGroupOption<T extends Id = Id> {
  disabled?: boolean;
  id: T;
  label: React.ReactNode;
  tooltip?: string;
  content?: React.ReactNode;
}

export type Value = RadioGroupOption['id'];

export interface RadioGroupProps extends WithStyles<typeof styles> {
  options: Array<RadioGroupOption>;
  error?: boolean;
  errorText?: string;
  value?: Id;
  label?: React.ReactNode;
  direction?: 'row' | 'column';
  disabled?: boolean;
  color?: 'primary' | 'secondary' | 'default';
  serializerType?: RadioGroupSerializerType;
  onChange?: (value: Id) => any;
}

const RadioGroupComponent: React.FC<RadioGroupProps> = ({
  classes,
  options,
  value,
  disabled,
  onChange,
  color = 'primary',
  direction = 'column',
  label,
  error,
  errorText,
  serializerType = RadioGroupSerializerType.number,
}) => {
  const name = React.useMemo(() => {
    return options.map(({ id }) => id).join();
  }, [options]);

  const handleSerializeValue = (val: Value) => {
    if (typeof val === 'undefined') {
      return '';
    }

    const serializers = {
      [RadioGroupSerializerType.string]: String,
      [RadioGroupSerializerType.number]: Number,
    };

    const serialize = serializers[serializerType];

    return serialize(val);
  };

  const renderRadio = (option: RadioGroupOption) => {
    const radio = (
      <>
        <Radio
          disabled={disabled || option.disabled || false}
          key={option.id}
          value={option.id}
          label={option.label}
          color={color}
          classes={{
            root: classes.formControl,
            label: classes.formControlLabel,
            radio: classes.formControlRadio,
            icon: cx({ [classes.formControlRadioError]: error }),
          }}
        />
        {option?.content && value == option.id && <div className={classes.additionalContent}>{option.content}</div>}
      </>
    );

    if (option.tooltip) {
      return (
        <Tooltip key={option.id} placement="top" title={option.tooltip}>
          <Flex direction="column" classes={{ root: classes.radioWrapper }}>
            {radio}
          </Flex>
        </Tooltip>
      );
    }

    return (
      <Flex key={option.id} direction="column" classes={{ root: classes.radioWrapper }}>
        {radio}
      </Flex>
    );
  };

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const normalizedValue = handleSerializeValue((event.target as HTMLInputElement).value);

    if (onChange) {
      onChange(normalizedValue);
    }
  };

  return (
    <div className={classes.root}>
      {label && (
        <FormLabel className={classes.label} component="legend">
          {label}
        </FormLabel>
      )}
      <MuiRadioGroup
        classes={{
          root: cx(
            classes.radios,
            { [classes.radiosRow]: direction === 'row' },
            { [classes.radiosColumn]: direction === 'column' }
          ),
        }}
        name={name}
        value={value}
        color={color}
        onChange={handleChange}
      >
        {options.map((option) => renderRadio(option))}
      </MuiRadioGroup>
      <FieldHelperText classes={{ root: classes.helperText }} error={error} errorText={errorText} />
    </div>
  );
};

export const RadioGroup = withStyles(styles)(RadioGroupComponent);
